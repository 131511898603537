import React from "react"

import Layout from "@src/components/Layout"
import Row from "../../V2/components/Row/Row"
import ImageRow from "../../V2/Features/components/ImageRow/ImageRow"
import Button from "../../../components/Button/Button"
import getAppUrl from "@src/utils/getAppUrl"
import DotBG from "../../V2/components/DotBG/DotBG"
import CalloutWithDemoForm from "../../../components/CalloutWithDemoForm/CalloutWithDemoForm"
import Column from "../../V2/components/Column/Column"
import Splash from "../../V2/Features/components/Splash"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import HeaderImage from "@src/icons/industries/banking_header.png"
import SectionOne from "@src/icons/industries/banking_section_one.png"
import SectionTwo from "@src/icons/industries/banking_section_two.png"
import SectionThree from "@src/icons/industries/banking_section_three.png"
import CardOne from "@src/icons/industries/banking_card_one.png"
import CardTwo from "@src/icons/industries/banking_card_two.png"
import CardThree from "@src/icons/industries/banking_card_three.png"
import OG from "@src/icons/og/OG_Banking.jpg"
import * as styles from "./Banking.module.scss"

const Financial = () => {
  const appUrl = getAppUrl.hook()
  const breakpoints = useBreakpoint()

  return (
    <Layout
      title="Power-up your banking operations with no-code solutions "
      description="Eliminate Manual Processes and Boost Efficiency with Automation in Banking"
      url="https://canonic.dev/industries/financial-services"
      ogImage={OG}
    >
      <Splash
        title="Power-up your banking operations with no-code solutions "
        subtitle="Eliminate Manual Processes and Boost Efficiency with Automation in Banking"
        illustration={<img src={HeaderImage} />}
        ctas={[
          <a href={appUrl} target="_blank" rel="noopener noreferrer">
            <Button isPrimary>Try for free</Button>
          </a>,
          <a href={"#get-a-demo"}>
            <Button borderless marginLeft>
              Get a Demo
            </Button>
          </a>,
        ]}
        reverseOnMobile
        className={styles.banking}
      />
      <Row>
        {breakpoints.mobile ? (
          <DotBG height={"120%"} reverse />
        ) : (
          <DotBG height={"calc(100% + 256px)"} offset={"-120px"} reverse />
        )}
        <div className={styles.bankingSection}>
          <h2>Overcome Banking Industry Challenges with Canonic </h2>
          <p>
            Are you struggling to keep up with the ever-changing demands of your
            customers in the banking industry?
          </p>
          <p>
            Manual processes that are slow, inefficient, and error-prone are no
            longer viable options. It's time to revolutionize your banking
            operations with Canonic. Our no-code solution enables users to build
            custom solutions quickly and easily, without needing extensive
            coding knowledge and resources. With Canonic, you can ensure
            compliance while offering a personalized and seamless experience to
            your customers, meeting their evolving needs.
          </p>
        </div>
      </Row>
      <section>
        <Row className={styles.bankingImageRow}>
          {breakpoints.mobile ? (
            <DotBG height={"150%"} offset={"-4.5%"} reverse />
          ) : (
            <DotBG height={"calc(100% + 256px)"} offset={"-130px"} reverse />
          )}
          <h2>Why No-Code is a Game-Changer for Banking Operations</h2>
          <ImageRow
            title="Efficient Development"
            subtitle="Canonic’s NoCode solution speeds up time to market and eliminates the need for costly development resources, allowing for faster and more efficient development."
            screenshot={SectionOne}
            borderless
            className={styles.retailProcessSources}
          />
          <ImageRow
            title="Rapid Iterations"
            subtitle="Canonic enables rapid iteration, which helps to accelerate the development process and allows for more agile adaptation to changing market needs."
            screenshot={SectionTwo}
            reverse
            borderless
            className={styles.retailProcessBoost}
          />
          <ImageRow
            title="Enhanced Efficiency "
            subtitle="Automation with Canonic reduces errors and enables personalized customer experiences, improving efficiency and satisfaction."
            screenshot={SectionThree}
            borderless
            className={styles.retailTailorMade}
          />
        </Row>
      </section>

      <section>
        <Row className={styles.bankingCards}>
          {breakpoints.mobile ? (
            <DotBG height={"110%"} offset={"-8%"} reverse />
          ) : (
            <DotBG height={"calc(100% + 256px)"} offset={"-165px"} reverse />
          )}
          <h2 className={styles.retailCardsTitle}>
            Business Benefits in Banking
          </h2>
          <div className={styles.retailCards}>
            <Column.Wrap>
              <Column
                title="Intuitive Interface and Pre-Built Templates"
                icon={CardOne}
                description="Canonic offers drag-and-drop and pre-built templates for custom application development without coding expertise. This saves on development costs and speeds up time-to-market, giving a competitive edge."
              />
              <Column
                title="Seamless Integration with Existing Systems"
                icon={CardTwo}
                description={
                  "Canonic seamlessly integrates with your existing systems, such as CRMs or ERPs, enhancing your current infrastructure without disrupting your operations. Ensuring custom solutions work seamlessly with your current processes."
                }
              />
              <Column
                title="Custom Solutions and Risk Management: "
                icon={CardThree}
                description={
                  "Canonic enables you to build custom solutions for compliance and risk management eg: KYC compliance & fraud detection. These solutions can be tailored to your specific needs, reducing the risk of financial losses and ensuring regulatory compliance."
                }
              />
            </Column.Wrap>
          </div>
        </Row>
      </section>

      <CalloutWithDemoForm
        originPage="Canonic for Financial"
        id="get-a-demo"
        title="Don't let manual processes hold you back. With Canonic, you can power up your banking operations and stay ahead of the curve."
      />
    </Layout>
  )
}

export default Financial
